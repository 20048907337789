import { defineStore } from 'pinia';

export const useModalStore = defineStore('modal', {
    state: () => ({
        SignIn: false,
        SignUp: false,
        ResetPassword: false,
        Subscribe: false,
    }),
    actions: {
        toggleModal(popupId: string, popupState: any) {
            this.SignIn = false;
            this.SignUp = false;
            this.ResetPassword = false;
            this.Subscribe = false;
            (this as any)[popupId] = popupState;
            if (popupState) {
                this.disableScroll();
            } else {
                this.enableScroll();
            }
        },
        disableScroll() {
            document.body.style.overflow = 'hidden';
        },
        enableScroll() {
            document.body.style.overflow = 'visible';
        },
    },
});
